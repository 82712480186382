.cb-p-img-logo {
	height: 100px;
	width: 100px;
	border: 1px solid black;
}

.cb-p {
	background-color: #6EB26F;
}  

.cb-p-border-left {
	background-color: #6EB26F;
	border: 1px solid black;
}

.cp-b-cell {
	white-space: normal;
	overflow: visible;
}

.cb-p-filter-checkbox {
	background-color: #6EB26F;
}

#table_description .tabulator-cell {
	white-space: normal;
}